<template>
  <v-footer
    tile
    class="font-weight-medium"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-container class="pt-0 pb-0 text-center">
        <strong class="text--disabled">
          © {{ new Date().getFullYear() }} Scan N Go || Terms and Legal
        </strong>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterLayout",
  computed: {
    ...mapGetters(["getThemeMode"]),
  }
};
</script>
